import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import delay from 'lodash/delay';
import { useRouter } from 'next/router';
import { closeModal } from 'modules/modals';

import Modal from './Modal';

const activateDomModal = () => {
  document.querySelector('body').classList.add('modal-active');
  document.querySelector('body').classList.remove('out');
};

const deactivateDomModal = (scrollY, shouldResetScroll) => {
  const body = document.querySelector('body');
  const container = document.querySelector('#modal-container');
  body.classList.remove('modal-active');
  if (container) {
    container.classList.add('out');
  }

  if (shouldResetScroll) {
    window.scrollTo(0, scrollY);
  }
};

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const ModalConductor = () => {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.modals);
  const scrollPositionRef = useRef(0)
  const router = useRouter()
  const routeRef = useRef(router.asPath)
  const isVisible = modal && modal.get('show');

  const onModalClose = () => {
    const shouldResetScroll = routeRef.current === router.asPath
    deactivateDomModal(scrollPositionRef.current, shouldResetScroll);
    delay(() => dispatch(closeModal()), 250);
  };

  useIsomorphicLayoutEffect(() => {
    if (isVisible) {
      scrollPositionRef.current = window.scrollY;
      routeRef.current = router.asPath
      activateDomModal();
    } else {
      const shouldResetScroll = routeRef.current === router.asPath
      deactivateDomModal(scrollPositionRef.current, shouldResetScroll);
    }
  }, [isVisible, router.asPath])

  if (isVisible) {
    return <Modal hideModal={onModalClose} modal={modal} />;
  }

  return null;
};

export default ModalConductor;
